<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--user-activate">
        <div class="mx-auto w-full max-w-xl">
            <h2 class="text-center text-3xl font-extrabold">
                {{ $t('auth.activate.title') }}
            </h2>
        </div>
        <div class="mt-8 mx-auto w-full max-w-xl modal">
            <div class="content-container py-8 px-4 sm:rounded-sm sm:px-10">

                <div class="flex mb-5 p-3 rounded-sm alert-danger" v-show="invalid">
                    <div class="flex-grow">Invalid: {{ invalid }}</div>
                    <!-- <div class="flex-grow">{{ $t('auth.passwordreset.emailnotfound') }}</div> -->
                </div>
                <form class="space-y-6" @submit.prevent="submit">
                    <div>
                        <label for="activation_code" class="block text-sm font-medium text-gray-700">
                            {{ $t('fields.activationcode') }} *
                        </label>
                        <div class="mt-1">
                            <input v-model="form.activation_code" @focus="invalid = false" id="activation_code" name="activation_code" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                        </div>
                    </div>
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">
                            {{ $t('fields.email') }} *
                        </label>
                        <div class="mt-1">
                            <input v-model="form.email" @focus="invalid = false" id="email" name="email" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                        </div>
                    </div>
                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ $t('fields.password') }} *
                        </label>
                        <div class="mt-1">
                            <input v-model="form.password" @focus="invalid = false" id="password" name="password" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                        </div>
                    </div>
                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ $t('fields.confirmpassword') }} *
                        </label>
                        <div class="mt-1">
                            <input v-model="form.password_confirmation" @focus="invalid = false" id="password_confirmation" name="password_confirmation" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                        </div>
                    </div>
                    <div>
                        <button @click="submit()" class="w-full flex justify-center py-3 px-4 btn btn-primary rounded-sm shadow-sm text-sm font-medium">
                            {{ $t('auth.activate.button.submit') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import * as _api from '../lib/api';
import * as _state from '../lib/state';

export default {
    name: 'UserActivate',
    components: {
    },
    data () {
        return {
            invalid: false,
            valid: false,
            saving: false,
            form: {
                activation_code: this.$route.query.code,
                email: this.$route.query.email,
                password: '',
                password_confirmation: '',
            }
        };
    },
    async mounted () {
        // this.init();
    },

    methods: {

        reset() {
            this.saving = false;
            this.form = {
                password: '',
                password_confirmation: '',
            }
        },

        async submit () {
            if (this.saving === true) {
                return;
            }
            // this.saving = true;

            const response = await _api.post('customer/activate', this.form);

            if (response.result === 'failed') {
                this.invalid = response.message;
                this.reset();

                return;
            }

            _state.set('token/setToken', response.data.token);

            // set user
            _state.set('user/setUser', {
                authenticated: true,
                username: response.data.username,
                hasAccounts: response.data.has_accounts,
                hasWallets: response.data.has_wallets,
            });

            // _notification.set(this.$t('notification.auth.account-activated'), 'success');

            if (response.data.has_wallets === true) {
                this.$router.push({name: 'user.myaccount.wallet'});

                return;
            }
            this.$router.push({name: 'landing'});
        }
    },
};
</script>
